<script setup>
import { ref, onMounted, onUnmounted } from "vue";

const sections = [
  {
    title: "Développement de solutions logicielles et web",
    description: [
      "Création et refonte de sites internet",
      "Développement d'applications mobiles",
      "Développement de logiciels sur mesure",
      "Développement et gestion de bases de données",
    ],
    image: require("@/assets/skills/software.jpg"), // ✅ Use require() for Webpack
    alt: "Développement logiciel et web",
  },
  {
    title: "Développement de solutions embarquées et automatisées",
    description: [
      "Conception et prototypage de circuits électroniques",
      "Développement de systèmes automatiques",
      "Développement et programmation de robots",
      "Conception et implémentation de systèmes embarqués",
      "Conception de circuits intégrés et microélectronique",
    ],
    image: require("@/assets/skills/embarque.avif"),
    alt: "Systèmes embarqués et automatisation",
  },
  {
    title:
      "Développement d'applications en intelligence artificielle et Machine Learning",
    description: [
      "Conception et optimisation d'algorithmes d'IA",
      "Développement d'applications de Machine Learning",
      "Analyse et exploitation de données massives (Big Data)",
    ],
    image: require("@/assets/skills/ai.webp"),
    alt: "Intelligence artificielle et Machine Learning",
  },
  {
    title: "Sécurisation des réseaux et cybersécurité",
    description: [
      "Traitement et analyse du signal",
      "Sécurisation des réseaux et cybersécurité",
      "Conception et optimisation des communications numériques",
      "Développement d'applications et solutions IoT",
    ],
    image: require("@/assets/skills/cyber.jpg"),
    alt: "Cybersécurité et réseaux",
  },
  {
    title: "Modélisation et simulation mécanique avancée",
    description: [
      "Modélisation et simulation numérique pour la mécanique des fluides",
      "Développement et optimisation d'algorithmes de calcul haute performance",
      "Calcul et simulation numérique de résistance des matériaux",
      "Etude de la dynamique de structures mécaniques",
    ],
    image: require("@/assets/skills/meca.jpg"),
    alt: "Simulation mécanique et modélisation",
  },
];

const currentIndex = ref(0);
let intervalId = null;
const isPaused = ref(false);

const nextSection = () => {
  currentIndex.value = (currentIndex.value + 1) % sections.length;
};

const goToSection = (index) => {
  currentIndex.value = index;
  restartInterval();
};

const restartInterval = () => {
  clearInterval(intervalId);
  if (!isPaused.value) {
    intervalId = setInterval(nextSection, 10000);
  }
};

const togglePause = () => {
  isPaused.value = !isPaused.value;
  restartInterval();
};

onMounted(() => {
  intervalId = setInterval(nextSection, 10000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>

<template>
  <div class="skills container">
    <div class="background-hero">
      <div class="overlay"></div>
    </div>

    <div class="title animation-fade-in">
      <i class="ri-sparkling-2-fill"></i>
      <h1>Bénéficiez de notre expertise</h1>
      <i class="ri-sparkling-2-fill"></i>
    </div>

    <div class="carousel animation-fade-in">
      <div class="image-container">
        <img
          :src="sections[currentIndex].image"
          :alt="sections[currentIndex].alt"
        />
      </div>
      <div class="text-container">
        <h3>
          <p>
            {{ sections[currentIndex].title }}
          </p>
        </h3>
        <ul>
          <li v-for="(desc, i) in sections[currentIndex].description" :key="i">
            {{ desc }}
          </li>
        </ul>
      </div>
    </div>

    <div class="carousel-controls animation-fade-in">
      <button @click="togglePause" class="pause-btn">
        <i
          :class="isPaused ? 'ri-play-circle-fill' : 'ri-pause-circle-fill'"
          class="ri-2x"
        ></i>
      </button>
      <div class="dots">
        <span
          v-for="(section, index) in sections"
          :key="index"
          class="dot"
          :class="{ active: index === currentIndex }"
          @click="goToSection(index)"
        ></span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.background-hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: linear-gradient(100deg, rgba(195, 221, 255, 1), transparent 100%);
}

.skills * {
  /* outline: 1px solid red; */
}

.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  gap: 2rem;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  & i {
    font-size: 3.5rem;

    &:nth-child(1) {
      color: var(--primary);
    }
    &:nth-child(2) {
      color: var(--accent);
    }
  }

  & h1 {
    font-size: 3.5rem;
    font-weight: 700;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(30deg, var(--text), var(--primary));
  }
}

/* Content */

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 50vh;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  border-radius: 1rem;
}

.image-container img {
  position: relative;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  transition: opacity 1s ease-in-out;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -0.2rem;
    border-radius: 22px;
    /* background: linear-gradient(to top right, transparent, #7f58b1); */
    background: conic-gradient(
      from var(--angle),
      var(--background) 40%,
      var(--primary)
    );
    animation: spin 5s linear infinite;
  }

  &:before {
    filter: blur(15px);
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes spin {
  from {
    --angle: 0deg;
  }

  to {
    --angle: 360deg;
  }
}

.text-container {
  flex: 1;
  padding: 2rem;
  text-align: left;
}

h3 * {
  /* outline: 1px solid red; */
}

h3 {
  /* outline: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  & p {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    line-height: 1.2;
  }

  & i {
    font-size: 2.5rem;
    color: var(--primary);
    line-height: 1.2;
  }
}

ul {
  list-style: none;
  padding: 0;
}

li {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

/* Controls */

.carousel-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: -2rem;
}

.pause-btn {
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  transition: all 0.3s ease-in-out;

  & i {
    border-radius: 100px;
    color: var(--accent);
    transition: all 0.3s ease-in-out;
    opacity: 0.8;
  }

  &:hover {
    scale: 1.1;
    & i {
      opacity: 1;
    }
  }
}

.dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.dots span {
  width: 12px;
  height: 12px;
  background-color: var(--accent);
  border-radius: 100px;
  cursor: pointer;
  opacity: 0.5;

  transition: all 0.3s ease-in-out;
  &:hover {
    scale: 1.1;
  }
}

.dots span.active {
  width: 25px;
  opacity: 0.8;
}

/* animations */
.animation-fade-in {
  &:nth-child(1) {
    transition-delay: 0ms;
  }
  &:nth-child(2) {
    transition-delay: 200ms;
  }
  &:nth-child(3) {
    transition-delay: 400ms;
  }
}
</style>
