<script>
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      form: {
        name: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  methods: {
    sendEmail() {
      const serviceID = "service_s2s0a5a"; // Replace with your EmailJS service ID
      const templateID = "template_v5pg3tu"; // Replace with your EmailJS template ID
      const userID = "foVHr7K1XuV3pMbRN"; // Replace with your EmailJS user ID

      const templateParams = {
        name: this.form.name,
        lastName: this.form.lastName,
        email: this.form.email,
        phone: this.form.phone,
        message: this.form.message,
      };

      emailjs
        .send(serviceID, templateID, templateParams, userID)
        .then((response) => {
          console.log("Message sent successfully:", response);
          alert("Your message has been sent!");

          // Reset form fields after successful submission
          this.form.name = "";
          this.form.lastName = "";
          this.form.email = "";
          this.form.phone = "";
          this.form.message = "";
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          alert("There was an error sending your message.");
        });
    },
  },
};
</script>

<template>
  <section class="contact container">
    <div class="background-hero"></div>
    <div class="hero">
      <div class="title animation-fade-in">
        <i class="ri-sparkling-2-fill"></i>
        <h1>Donnez vie à votre projet</h1>
        <i class="ri-sparkling-2-fill"></i>
      </div>
      <p class="animation-fade-in">
        Une question, un projet, une idée ?<br />
        N'hésitez pas à nous contacter, nous serons ravis de vous répondre.
      </p>
    </div>

    <div class="contact-form animation-fade-in">
      <form @submit.prevent="sendEmail">
        <div class="input-group">
          <div class="form-group">
            <label for="name">Prénom *</label>
            <input
              v-model="form.name"
              type="text"
              id="name"
              name="name"
              required
            />
          </div>
          <div class="form-group">
            <label for="lastName">Nom *</label>
            <input
              v-model="form.lastName"
              type="text"
              id="lastName"
              name="lastName"
              required
            />
          </div>
        </div>
        <div class="input-group">
          <div class="form-group">
            <label for="email">Email *</label>
            <input
              v-model="form.email"
              type="email"
              id="email"
              name="email"
              required
            />
          </div>
          <div class="form-group">
            <label for="phone">Téléphone *</label>
            <input
              v-model="form.phone"
              type="tel"
              id="phone"
              name="phone"
              required
            />
          </div>
        </div>
        <div class="form-group">
          <label for="message">Message *</label>
          <textarea
            v-model="form.message"
            id="message"
            name="message"
            placeholder="Dites-nous en plus sur votre projet..."
            required
          ></textarea>
        </div>
        <button type="submit">
          <i class="ri-mail-ai-fill"></i>
          <p>Send</p>
        </button>
      </form>
    </div>
  </section>

  <section class="contact-info container">
    <h2 class="animation-fade-in">Si vous préférez, voici nos coordonées</h2>
    <div class="contact-info-content">
      <a
        href="mailto:contact@junior-aei.com?subject=Prise%20de%20contact%20-%20junior-aei"
        target="_blank"
        class="animation-fade-in"
      >
        <i class="ri-mail-unread-fill"></i>
        <p>contact@junior-aei.com</p>
      </a>

      <a href="tel:+33553044215" class="animation-fade-in">
        <i class="ri-phone-fill"></i>
        <p>+33 5 53 04 42 15</p>
      </a>
      <a
        href="https://www.google.com/maps/place/1+Avenue+du+Dr+Albert+Schweitzer,+33400+Talence/@44.8072968,-0.6062538,1163m/data=!3m2!1e3!4b1!4m6!3m5!1s0xd54d88197728bbf:0x3c55f555d022b3d5!8m2!3d44.8059524!4d-0.6053191!16s%2Fg%2F11bw3h66bh?entry=ttu&g_ep=EgoyMDI1MDMxNi4wIKXMDSoASAFQAw%3D%3D"
        target="_blank"
        class="animation-fade-in"
      >
        <i class="ri-map-pin-fill"></i>
        <p>1 Avenue du Dr Albert Schweitzer, 33400 Talence</p>
      </a>
    </div>
  </section>
</template>

<style scoped>
.background-hero {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
  background: linear-gradient(
    180deg,
    var(--secondary) 0%,
    var(--background) 100%
  );
  /* opacity: 0.5; */
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  min-height: 100vh;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  text-align: center;

  & .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    & .ri-sparkling-2-fill {
      font-size: 2.5rem;

      &:nth-child(1) {
        color: var(--primary);
      }
      &:nth-child(2) {
        color: var(--accent);
      }
    }

    & h1 {
      font-size: 3.5rem;
      font-weight: 700;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(30deg, var(--text), var(--primary));
    }
  }

  & p {
    color: var(--text);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5;
    transition-delay: 200ms;
  }
}

.contact-form {
  position: relative;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */

  width: 100%;
  max-width: 800px;
  padding: 2.5rem;
  background: var(--background);
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  transition-delay: 400ms;

  & form {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 1.5rem;

    & .input-group {
      display: flex;
      gap: 2rem;
    }

    & .form-group {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      flex: 1;

      & label {
        font-weight: 500;
      }

      & input,
      & textarea {
        padding: 0.5rem;
        border: none;
        border-radius: 8px;
        outline: 2px solid var(--secondary);

        transition: outline 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

        &:focus {
          outline: 2px solid transparent;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        }

        &:focus::placeholder {
          transition: all 0.3s ease-in-out;
          color: transparent;
        }
      }

      & textarea {
        resize: vertical;
        min-height: 100px;
        max-height: 250px;
      }
    }

    & button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem;
      width: 80%;
      max-width: 400px;
      background: var(--secondary);
      border: none;
      border-radius: 100px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      outline: 2px solid transparent;
      margin: auto;

      & i {
        font-size: 1.5rem;
        color: var(--text);
        transition: all 0.3s ease-in-out;
      }

      & p {
        color: var(--text);
        font-weight: 500;
        font-size: 1.25rem;
        transition: all 0.3s ease-in-out;
      }

      &:hover,
      &:focus {
        outline: 2px solid var(--primary);

        & i,
        & p {
          color: var(--primary);
        }
      }
    }
  }
}

/* Contact Informations */

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin: 5rem 0 10rem 0;

  & h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--accent);
  }
}

.contact-info-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  & a {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.1);
    background: linear-gradient(40deg, var(--secondary), transparent);
    outline: 2px solid var(--secondary);

    & p {
      color: var(--text);
      font-size: 1.25rem;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
    }

    & i {
      color: var(--text);
      font-size: 1.5rem;
      transition: all 0.3s ease-in-out;
    }

    transition: all 0.3s ease-in-out;
    &:hover {
      outline: 2px solid var(--primary);
      scale: 1.02;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

      & i,
      & p {
        color: var(--primary);
      }
    }

    &:nth-child(1) {
      transition-delay: 0ms;
    }
    &:nth-child(2) {
      transition-delay: 200ms;
    }
    &:nth-child(3) {
      transition-delay: 400ms;
    }
  }
}
</style>
