<script>
// import HelloWorld from "@/components/HelloWorld.vue";
import { scrollToElement } from "@/main.js";

export default {
  name: "HomeView",
  components: {
    // HelloWorld,
  },
  methods: {
    scrollToElement(element, offset) {
      scrollToElement(element, offset);
    },
  },
};
</script>

<template>
  <div class="background-hero">
    <img src="@/assets/team/group_full.jpg" alt="hero" />
    <div class="mask1"></div>
    <div class="mask2"></div>
  </div>

  <div class="hero container">
    <div class="cta" @click="scrollToElement('work-with-us', 20)">
      <i class="ri-team-fill"></i> Découvrir notre Equipe
    </div>
  </div>

  <section class="team-content container">
    <div class="work-with-us">
      <div class="content">
        <h2 class="animation-fade-in">
          <p>Travailler avec nous</p>
          <i class="ri-shake-hands-line"></i>
        </h2>
        <p class="animation-fade-in">
          Choisir de travailler avec AEI, c'est choisir de travailler avec une
          junior de plus de 37 ans d'expérience, des étudiants de l'une des
          meilleures écoles d'ingénieurs dans les domaines qu'elle enseigne.
          Mais aussi une équipe motivée, engagée et entreprenante qui ferra tous
          les efforts possibles pour répondre à chacun de vos besoins.
        </p>
      </div>
      <div class="image animation-fade-in">
        <img src="@/assets/team/enseirb.jpg" alt="team" />
      </div>
    </div>

    <div class="work-with-us">
      <div class="image animation-fade-in">
        <img src="@/assets/team/fablab.jpg" alt="team" />
      </div>
      <div class="content">
        <h2 class="animation-fade-in">
          <p>Notre environnement</p>
          <i class="ri-planet-line"></i>
        </h2>
        <p class="animation-fade-in">
          La junior peut s'appuyer sur les étudiants de l'ENSEIRB, étant l'une
          des meilleures écoles d'ingénieurs technique de France. Ces étudiants
          peuvent aussi compter sur l'aide d'enseignants chercheurs lors des
          tâches les plus ardues. Enfin, les intervenants possèdent des
          équipements à la pointe afin de répondre à vos besoins avec le fablab
          de l'école.
        </p>
      </div>
    </div>

    <div class="work-with-us">
      <div class="content">
        <h2 class="animation-fade-in">
          <p>Une équipe motivée</p>
          <i class="ri-team-line"></i>
        </h2>
        <p class="animation-fade-in">
          L'équipe d'AEI est composée de 25 étudiants de l'ENSEIRB-MATMECA. Ces
          administrateurs donneront le meilleur d'eux même afin de rendre votre
          projet tangible !
        </p>
      </div>
      <div class="image animation-fade-in">
        <img src="@/assets/team/group_fun.jpg" alt="team" />
      </div>
    </div>
  </section>
</template>

<style scoped>
section {
  margin: 5rem 0;
}

.background-hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  /* overflow: hidden; */

  & img,
  & .mask1 {
    position: absolute;
    top: 1vh;
    left: 0.5vw;
    width: 99vw;
    height: 98vh;
    border-radius: 20px;
  }

  & .mask1 {
    background: radial-gradient(
      circle at bottom,
      transparent 50%,
      rgba(47, 39, 206, 0.4)
    );
  }

  & img {
    object-fit: cover;
    filter: brightness(0.7);
  }

  & .mask2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: conic-gradient(
      from var(--angle) at 50% 50%,
      rgba(47, 39, 206, 1) 0deg,
      rgba(251, 251, 254, 1) 100deg,
      rgba(47, 39, 206, 1) 360deg
    );
    filter: blur(10px);
    animation: spin 5s linear infinite;
  }
}

/* var angle */
@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes spin {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 2rem;

  & h1 {
    font-size: 4rem;
    text-align: center;
    color: var(--background);
    font-weight: 500;
    width: 60%;
    line-height: 1.5;
  }
}

.cta {
  position: absolute;
  bottom: 20%;

  padding: 1rem 2rem;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.1);
  outline: 1px solid var(--background);
  backdrop-filter: blur(10px);
  color: var(--background);
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;

  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  & i {
    font-size: 1.5rem;
    font-weight: 500;
    margin-right: 0.5rem;
    color: var(--background);
  }
}

/* Content */

.team-content {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15rem;
  margin-top: 15rem;
  margin-bottom: 10rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  width: 100%;
}

.work-with-us {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  & h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 10px;
    width: 100%;
    /* background: linear-gradient(40deg, rgba(47, 39, 206, 0.4), transparent); */
    /* outline: 2px solid rgba(47, 39, 206, 0.6); */

    & p {
      text-wrap: nowrap;
      font-size: 3.5rem;
      font-weight: 700;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(30deg, var(--text), var(--primary));
    }

    & i {
      font-size: 3.5rem;
      color: var(--primary);
    }
  }

  & p {
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.5;
    color: var(--text);
    font-weight: 400;
  }
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}
</style>
